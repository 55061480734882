.coming-soon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    background-color: #f0f0f0;
  }
  
  h1 {
    font-size: 3rem;
    text-align: center;
    margin: 0;
    padding: 1rem;
    cursor: pointer;
  }
  