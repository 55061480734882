.app{

  .link{
    color: inherit;
    text-decoration: none;
  }

  .ts-name{
    color: black;
    display: flex;
    justify-content: center;
    font-size: 30px;
  
  }
}