// .footer {
//     margin: 100px 200px 20px 200px;
    
  
//     .top {
//       display: flex;
//       gap: 50px;
      
  
//       .item {
//         flex: 1;
//         display: flex;
//         flex-direction: column;
//         gap: 10px;
//         text-align: justify;
//         font-size: 14px;
  
//         h1{
//           font-size: 18px;
//           font-weight: 500;
//           color: #555;
//         }
  
//         span{
//           color: gray;
//         }
//       }
//     }
  
//     .bottom {
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       margin-top: 50px;
//       .left {
//         display: flex;
//         align-items: center;
  
//         .logo{
//           color: #2879fe;
//           font-weight: bold;
//           font-size: 24px;
//         }
  
//         .copyright{
//           margin-left: 20px;
//           font-size: 12px;
//           color: gray;
//         }
//       }
//       .right {
  
//         img{
//           height: 50px;
//         }
//       }
//     }
//   }


  .footer {
    background-color: #f2f2f2;
    padding: 20px;
    color: #333;
    font-size: 14px;
  }

  .top {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .item {
    flex: 1;
  }

  .item h1 {
    font-size: 18px !important;
    margin-bottom: 10px;
    font-weight: 500;
    color: #555;
  }

  span {
    display: block;
    margin-bottom: 5px;
    color: gray;
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .left {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-logo {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #2879fe;
  }

  .right img {
    height: 50px;
  }

  .copyright {
    font-size: 12px;
    color: #666;
  }

  @media (max-width: 768px) {
    .top {
      flex-direction: column;
      gap: 10px;
    }

    .item {
      flex: none;
      width: 100%;
    }

    .about-section{
      display: none;
    }

    .contact-section{
      display: none;
    }

    .bottom {
      flex-direction: column;
      text-align: center;
    }

    .right img {
      margin-top: 10px;
      width: 80vw;

    }

    .categories-list{
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .links-list{
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }