// .contact{
//   background-color: #1f2937;
//   color: white;
//   padding: 15px;
//   display: flex;
//   justify-content: center;

//   .wrapper{
//     width: 50%;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;

//     input{
//       padding: 10px;
//       border: none;
//       border-radius: 5px 0 0  5px;
//     }

//     button{
//       padding: 10px;
//       color: white;
//       background: #333;
//       border-radius: 0 5px 5px 0;
//       border: none;
//     }

//     .icons{
//       display: flex;
//       gap: 10px;
//     }
//   }
// }


.contact {
  background-color: #1f2937;
  color: #fff;
  padding: 20px;
}

.contact>.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contact>.wrapper>span {
  font-size: 18px;
  margin-bottom: 10px;
  color: #fff;
}

.mail {
  // display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 15px;
}

input {
  padding: 10px;
  border: none;
  border-radius: 5px 0 0 5px;
}


button {
  align-items: center;
  padding: 10px;
  color: white;
  background: #333;
  border-radius: 0 5px 5px 0;
  border: none;
  cursor: pointer;
}

.icons {
  font-size: 24px;
  display: flex;
  gap: 10px;
}


@media (max-width: 768px) {

  .contact>.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mail {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }

  button {
    width: 100%;
  }
}