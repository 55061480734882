.slider{
    height: calc(100vh - 80px);
    width: 99vw;
    position: relative;
    overflow: hidden;
  
    .container{
      width: 300vw;
      height: 100%;
      display: flex;
      transition: all 1s ease;
  
      img{
        width: 100vw;
        height: 100%;
        object-fit: cover;
      }
    }
  
    .icons{
      width: fit-content;
      display: flex;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 100px;
      gap: 10px;
      margin: auto;
  
      .icon{
        width: 50px;
        height: 50px;
        border: 1px solid #999;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }


  @media (max-width: 768px) {
    .slider{
      height: calc(100vh - 80px);
    width: 95vw;
    position: relative;
    overflow: hidden;

    }
  }