
/* Navbar styles */
.navbar {
  background-color: #1f2937;
  color: white;
  padding: 10px 0;
  position: relative;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}



.menu {
  display: flex;
  align-items: center;
}

.item {
  margin-right: 20px;
}

.link {
  text-decoration: none;
  color: white;
}

.hamburger-icon {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.hamburger-icon.open {
  transform: rotate(90deg);
}

.bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 3px 0;
}

.mobile-menu {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  background-color: #333;
  z-index: 1;
}

.mobile-menu.active {
  display: flex;
}

.hamburger-menu.active {
  display: flex;
}

.mobile-menu .item {
  margin: 10px;
}

.right {
  display: flex;
  align-items: center;
  gap: 25px;

  .icons {
    display: flex;
    gap: 15px;
    color: #777;
    cursor: pointer;

    .cartIcon {
      position: relative;

      span {
        font-size: 12px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #2879fe;
        color: white;
        position: absolute;
        right: -10px;
        top: -10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media (min-width: 769px) {
  .hamburger-menu{
    display: none ;
  }

  .icons-mobile{
    display: none;
  }

  .logo {
    flex-grow: 1;
    text-align: start;
    font-size: 24px;
  }
}

/* Mobile styles */
@media (max-width: 768px) {

  .navbar{
    padding: 1rem;
  }
  .menu {
    display: none;
  }

  .logo {
    flex-grow: 1;
    text-align: center;
    font-size: 24px;
  }

  .icons {
    display: none;
  }


  .cartIcon-mobile {
    position: relative;

    span {
      font-size: 12px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #2879fe;
      color: white;
      position: absolute;
      right: -10px;
      top: -10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

